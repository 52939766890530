import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Santé",
  pageDescription:
    "See how a chef-owner stays fully booked with SpotOn Reserve.",
  title: "Boosting Bookings with SpotOn Reserve",
  subTitle: `How a fine dining concept boosts profit margins with more bookings.`,
  businessName: "Santé",
  industry: "Restaurant, fine dining",
  location: "Charlotte, North Carolina",
  products: [spotonProducts.RESERVE],
  description:
    "Chef Adam Reed and his wife and business partner Veronica Reed have a lot to juggle. His days are filled with butchering and kitchen prep and his nights are spent by the stovetop, plating french-influenced fine dining cuisine with the utmost care. Veronica Reed oversees a bustling front-of-house. With their limited bandwidth, they operate a small restaurant with limited covers–they don’t have time to sit waiting by the phone, but they can't afford to miss out on bookings either.",
  quoteData: {
    imgName: "sante.png",
    quote: `“There are no slow nights any more. We used to hope to get to half capacity. Now we know we’re going to do 60% capacity every week night and the weekends are always busy.”`,
    personName: "Adam Reed",
    personTitle: "Chef and Owner, Santé",
  },
  goal: `With food and labor costs going up, Reed needed to increase profitability. For Santé, this meant incorporating their 20-person patio into their <a href="/products/reserve/" target="_blank">restaurant reservation system</a> and minimizing the time spent taking reservations over the phone. They needed a restaurant reservation solution that would allow them to block out prime times to increase table turns on busy weekends.`,
  solution: `With SpotOn Reserve, Santé can max out reservations without the increased labor spend on a dedicated staff member. Front-of-house manager Veronica Reed can run a more efficient reservation operation and Santé’s staff has more time to attend to customers rather than a ringing phone. Santé increased their visibility by integrating SpotOn Reserve with Google. Automated confirmation emails mean reduced cancellations and no-shows and Santé can increase table turns during peak evenings by blocking out prime slots. Best of all, Chef Reed no longer has to interrupt his butchering to take a reservation. Less gloves, more bookings.`,
  results: {
    title: `The results`,
    stats: [
      {
        title: `40%`,
        description: `Increase in reservations with online booking`,
      },
      {
        title: `7 hours`,
        description: `saved per week on managing reservations`,
      },
      {
        title: `25%`,
        description: `Increase in yearly revenue`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "A southern restaurant sees profits go north",
    imageName: "southern-table.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-28",
    href: "/case-studies/southern-table",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Cooking up real Southern food with quick, seamless ordering",
    imageName: "wisteria.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-08-25",
    href: "/case-studies/wisteria",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Fewer spreadsheets. More teamwork",
    imageName: "guisto-newport.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-12-02",
    href: "/case-studies/guisto-newport",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
];
